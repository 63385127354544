import React from 'react';
import { Link } from 'gatsby';

import HeroPost from '../components/Hero/hero-post';
import Layout from '../components/layout';
import SEO from '../components/seo';

import ogImg from '../images/og/tools.png';
import heroImg from '../images/tools-hero.svg';

const ToolsPage = () => (
  <Layout>
    <SEO
      title="Tools"
      description="Tools made by Tomek Dev to help with development"
      ogUrl="https://tomekdev.com/tools"
      imageUrl={ogImg}
    />
    <HeroPost gradientFrom="#434343" gradientTo="#000000">
      <img src={heroImg} alt="Tools" width="842" height="259" />
    </HeroPost>

    <section>
      <div className="container">
        <h1 className="pageTitle">Tools</h1>

        <Link to="/tools/pendo-jzb-decoder">
          <h2 className="sectionTitle">Pendo jzb query param decoder</h2>
        </Link>

        <p>
          It helps with decoding Pendo jzb query param where the data send to
          Pendo's servers is stored.
        </p>
      </div>
    </section>
  </Layout>
);

export default ToolsPage;
